$red: #e5000f;
$blue: #0096e5;
$warning: #fdcf0f;
$success: #00a651;

$grays: (
	200: #eee,
	300: #d0d0d0,
	400: #d9d9d9,
	500: #9b9b9b,
	600: #999,
	700: #535252,
	800: #333,
);

$black: #000;
$white: #fff;
