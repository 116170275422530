@use 'sass:map';
@use '../settings/borders';
@use '../settings/colors';
@use '../settings/typography';
@use '../tools/breakpoint';
@use '../tools/spacing';
@use '../tools/transitions';
@use '../tools/units';

.flash {
	@include transitions.default(opacity);

	position: fixed;
	top: spacing.generate(4);
	right: 0;
	z-index: 300;
	padding: spacing.generate(2);
	text-align: center;
	border: borders.$width solid map.get(colors.$grays, 300);
	border-radius: borders.$radius;
	background-color: colors.$white;
	opacity: 0;
}

.flash__text {
	margin-bottom: 0;
	font-weight: map.get(typography.$font-weight-values, medium);
	font-size: map.get(typography.$font-size-values, -1);

	@include breakpoint.up(md) {
		font-size: map.get(typography.$font-size-values, 0);
	}
}

.flash--success {
	color: colors.$white;
	background-color: colors.$success;
}

.flash--visible {
	opacity: 1;
}
