@use 'sass:map';
@use '../settings/breakpoints';

@mixin up($breakpoint, $retina: false) {
	@if (not map.has-key(breakpoints.$values, $breakpoint)) or $breakpoint == 'xxs' {
		@if $retina {
			@media (min-resolution: 192dpi) {
				@content;
			}
		} @else {
			@content;
		}
	} @else {
		@if $retina {
			@media (min-resolution: 192dpi) and (min-width: map.get(breakpoints.$values, $breakpoint)) {
				@content;
			}
		} @else {
			@media (min-width: map.get(breakpoints.$values, $breakpoint)) {
				@content;
			}
		}
	}
}
