@use '../settings/colors';

.game {
	width: 100%;
	height: 100%;
	background-color: colors.$black;
}

.wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 1500px;
	height: 100%;
	margin-inline: auto;
}
